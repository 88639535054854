<template>
  <div class="login">
    <div class="login__content">
      <div class="login__content--representative-glt">
        <h1 class="text-center">
          <b>We Are The</b
          ><img
            src="https://shipwithglt.com/wp-content/uploads/2018/09/DOS-FLECHAS.svg"
            width="15%"
            height="15%"
          />
        </h1>
        <h1 class="text-center"><b>Experts You Need</b></h1>
      </div>
      <div class="login__content__form">
        <img class="login__content__form--logo" src="../../assets/images/glt-logo.png" />
        <hr />

        <div class="login__content__form__submit">
          <b-form v-on:submit.prevent>
            <b-form-group
              id="input-group-2"
              label="User:"
              class="login__content__form__submit--user-label"
              label-for="input-2"
            >
              <div class="login__content__form__submit--input d-flex justify-content-center">
                <b-input-group-prepend is-text class="d-flex">
                  <b-icon
                    icon="person-fill"
                    class="login__content__form__submit--user-icon"
                  ></b-icon>
                </b-input-group-prepend>
                <b-form-input
                  name="username"
                  id="username"
                  v-model="username"
                  type="text"
                  autocomplete="username"
                  required
                ></b-form-input>
              </div>
            </b-form-group>

            <b-form-group label="Password:" class="login__content__form__submit--user-label">
              <div class="login__content__form__submit--input d-flex justify-content-center">
                <b-input-group-prepend is-text class="d-flex">
                  <b-icon icon="lock-fill" class="login__content__form__submit--user-icon"></b-icon>
                </b-input-group-prepend>
                <b-form-input
                  name="password"
                  id="password"
                  @keyup.enter="login()"
                  type="password"
                  autocomplete="password"
                  v-model="password"
                  required
                ></b-form-input>
              </div>
            </b-form-group>
          </b-form>
        </div>

        <div class="login__content__form__submit__options">
          <b-overlay
            :show="busy"
            rounded
            opacity="0.6"
            spinner-small
            class="d-inline-block"
            @hidden="onHidden"
          >
            <b-button
              ref="button-accept"
              :disabled="busy"
              class="login__content__form__submit__options--button"
              @click="login()"
              ><b>Accept</b></b-button
            >
          </b-overlay>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      username: null,
      password: null,
      busy: false,
    };
  },
  methods: {
    async login() {
      this.busy = true;
      const formData = new FormData();
      formData.append('username', this.username);
      formData.append('password', this.password);
      try {
        const response = await this.$store.dispatch('user/login', formData);
        if (response?.status === 200) {
          const decoded = this.$jwt.decode(response.data.access_token);
          localStorage.setItem('user-info', JSON.stringify(decoded));
          localStorage.setItem('user-access-token', response.data.access_token);
          this.$router.push({ path: '/scales' });
        }
      } catch (error) {
        if (error.response.status === 400) {
          this.showAlert('Error', 'The user is inactive');
        } else if (error.response.status === 401) {
          this.showAlert('Error', 'Incorrect username or password');
        } else {
          this.showAlert('There is something wrong', 'Please, try again');
        }
      }
      this.busy = false;
    },
    showAlert(title, text) {
      this.$swal({
        title: `${title}`,
        text: `${text}`,
        icon: 'error',
        showConfirmButton: true,
        confirmButtonColor: '#e53935',
      });
    },
    onHidden() {
      this.$refs['button-accept'].focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  min-height: $view-height;
  background-image: url("../../assets/images/login.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.6);

  &__content {
    position: absolute;
    left: 0;
    right: 0;
    top: -10%;
    bottom: 6%;
    overflow: auto;
    @include justify-items-center;
    @include flex-wrap(center);

    &--representative-glt {
      @include justify-items-center;
      color: $color-white;

      h1 {
        font-size: 70px;
      }
    }

    &__form {
      @include justify-items-center;
      width: 450px;
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: $border-radius;
      padding: 1%;
      box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.9),
      0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.9);

      hr {
        background-color: $color-white;
      }

      &--logo {
        @include justify-items-center;
        width: 150px;
        height: auto;
      }

      &__submit {
        padding: 20px;
        &--user-label {
          color: $color-white;
          text-align: left;
          margin-bottom: 1rem;
        }
        &--user-icon {
          color: $color-black;
        }
        &--input {
          .form-control {
            border-radius: 0px $border-radius $border-radius 0px;
          }
          .input-group-text {
            display: flex;
            background-color: $color-submit-button;
            color: $color-light;
            border: none;
            border-radius: $border-radius 0px 0px $border-radius;
          }
        }
        &__options {
          @include justify-items-center;
          margin: $margin-most-used 0px;

          &--button {
            @include submit-button;
          }
        }
      }
    }
  }
}

@media (max-width: 1225px) {
  h1,
  .h1 {
    display: none;
  }
  .login__content {
    flex-direction: column;
  }
}

@media (max-width: 470px) {
  .login__content__form {
    max-width: 300px;
  }
}
</style>
